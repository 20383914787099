import(/* webpackMode: "eager" */ "/tmp/build_2580513b/node_modules/.pnpm/next@15.2.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_2580513b/node_modules/.pnpm/next@15.2.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_2580513b/node_modules/.pnpm/next@15.2.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_2580513b/node_modules/.pnpm/next@15.2.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_2580513b/node_modules/.pnpm/next@15.2.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_2580513b/node_modules/.pnpm/next@15.2.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/components/metadata/async-metadata.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_2580513b/node_modules/.pnpm/next@15.2.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/components/metadata/metadata-boundary.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_2580513b/node_modules/.pnpm/next@15.2.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/components/render-from-template-context.js");
